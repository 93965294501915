


* {
  padding: 0;
  margin: 0;
  /* -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; */

  /* direction: rtl; */
  font-family: 'Almarai';
}



:root {
  --bgLight: #F5F8FA;
  --colorLight: #15202B;
  --bgDark: #15202B;
  --colorDark: #F5F8FA;
  --primary: #743C91;
  --green: #91CCC3;
  --gray: #858688;
  --transition: 0.3s;
}



body {
  overflow-x: hidden !important;

}

.App {
  background-color: var(--bgLight);

}


/* scroll with border radius */
.style-scroll-radius {
  overflow: auto;
}



/* for sidebar container only( Y) */
.style-sidebar-scroll-radius {
  overflow: auto;
  overflow-x: hidden;
}

body::-webkit-scrollbar,
.style-scroll-radius::-webkit-scrollbar,
.style-sidebar-scroll-radius::-webkit-scrollbar {
  background-color: #ddd;
  height: 7px;
  border-radius: 30px;
  width: 7px;
}

body::-webkit-scrollbar-thumb,
.style-scroll-radius::-webkit-scrollbar-thumb,
.style-sidebar-scroll-radius::-webkit-scrollbar-thumb {
  background-color: #753c91be;
  border-radius: 30px;
}

/* scroll without border radius */
.style-scroll::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0.4);
  height: 13px;
  width: 13px;
}

.style-scroll::-webkit-scrollbar-thumb {
  background-color: #753c91be;
  border-radius: 30px;
}

.Triangles {
  position: absolute;
  /* bottom: -35px; */
  top: 0;
  width: 100%;
  height: 35px;
  display: flex;
  background-image: linear-gradient(135deg, #fff 25%, transparent 25%),
    linear-gradient(225deg, #fff 25%, transparent 25%);
  background-size: 50px 50px;
}

.font-size-title {
  font-size: 16px;
  transition: var(--transition);
  font-weight: bold;

}

.font-size-subTitle {
  font-size: 14px;
  transition: var(--transition);

}

/*###########################  start header ##########################*/

.dropdown {
  border-radius: 50px !important;
}

.dropdown-item {
  font-size: 14px !important;
  padding: 10px 20px !important;
  background-color: #fff !important;
  cursor: pointer;
}


.dropdown-item:hover {
  background-color: #f3f3f3 !important;


}

.dropdown-item:active {
  background-color: #f8f9fa !important;
  color: var(--primary) !important;
  font-weight: bold !important;
}

.nav-item-link:hover {
  color: var(--primary) !important;
  font-weight: bold !important;
}



/*###########################  end header ##########################*/


/* button circle pill */
.hover-btn-pill {
  background-color: #fff;
  padding: 6px 0px;
  border: 3px solid var(--primary);
  border-radius: 50px;
  transition: var(--transition);
  font-weight: bold;
  color: var(--primary);
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.hover-btn-pill:hover {
  background-color: var(--primary);
  border-color: var(--primary) !important;
  color: #fff;
  border-radius: 50px;
}




/*###########################  start landing ##########################*/

.text-landing {
  font-size: 45px;
  text-align: center;
  transition: var(--transition);
}

/*###########################  end landing ##########################*/



/*###########################  start header-title ##########################*/

.container-header-title {

  display: flex;
  justify-content: center;
  align-items: center;
}

.container-header-title .header-title {
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  color: var(--primary);
  transition: var(--transition);
  margin: auto;
}

.container-header-title .header-title .line {
  position: relative;
  margin-top: 15px;


}

.container-header-title .header-title .line::before {
  content: "";
  position: absolute;
  width: 40%;
  background-color: var(--primary);
  border-radius: 30px;
  height: 3px;
  left: 0;
}

.container-header-title .header-title .line::after {
  content: "";
  position: absolute;
  width: 40%;
  background-color: var(--primary);
  border-radius: 30px;
  height: 3px;
  right: 0;
}

.container-header-title .header-title .line .circle {
  position: absolute;
  width: 20px;
  background-color: var(--primary);
  height: 20px;
  border-radius: 50px;
  left: 50%;
  transform: translate(-50%, -50%);

}


/*###########################  end header-title ##########################*/
.css-nfp1ut.react-dropdown-select-item-selected {
  color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(255, 255, 255);
  background: var(--primary) !important
}

.btn-more {
  transition: var(--transition);


}

.btn-more:hover {
  opacity: 0.6;
  transition: var(--transition);
}

.style-shadow {
  box-shadow: 0 0 10px rgba(31, 31, 31, 0.05)
}

.border-radius-contact-us-1 {
  border-radius: 0 8px 8px 0;
}

.border-radius-contact-us-2 {
  border-radius: 8px 0 0 8px;
}

/* change color placeholder the input */
.form-control {
  border: 3px solid #f6f6f6 !important;
  /* transition: 0 !important;
  box-shadow: 0 0 0 transparent !important; */
}

/* ficus border primary color */
.form-control:focus {
  border: 3px solid var(--primary) !important;
  transition: 0 !important;
  box-shadow: 0 0 0 !important;

}

.form-control::placeholder {
  /* background-color: red; */
  color: #1e1e1e65 !important;
  font-weight: bold;
  font-size: 13px;
  /* color: var(--black-color); */
  /* color: #1e1e1e65; */
  /* color: #f00; */
}

/* footer media */

.style-footer-media {
  display: flex;
}


.style-footer-media-link {
  color: #ececec;
  font-size: 28px;
  background-color: #8343a3;
  transition: 0.2s;
  padding: 12px;
  display: flex;
  border-radius: 50px;
  margin: 5px;
}

.style-footer-media-link-1:hover {
  color: #fff;
  background-color: #1D9BF0;
  transition: 0.2s;
}

.style-footer-media-link-2:hover {
  color: #fff;
  background-color: var(--green);
  transition: 0.2s;
}

.style-footer-media-link-3:hover {
  color: #fff;
  background-color: #EA4335;
  transition: 0.2s;
}

.style-footer-media-link-4:hover {
  color: #fff;
  background-color: #161B22;
  transition: 0.2s;
}


.react-dropdown-select-content,
.react-dropdown-select {
  /* background-color: red; */
  background-color: var(--bgLight);
  border: 3px solid #f6f6f6 !important;
  height: 49.33px;
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  border-radius: 0.375rem !important;
}

.css-1yc4zyy-DropdownHandleComponent,
.react-dropdown-select-dropdown-handle {
  /* background-color: green; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.e1vudypg0 {
  font-size: 20px;

}

.css-z2hyoe-ItemComponent {
  background-color: #fff;
  padding: 10px !important;
}

.css-z2hyoe-ItemComponent.react-dropdown-select-item-selected {
  background-color: var(--primary) !important;
}

/* media */

@media (max-width:992px) {
  .text-landing {
    font-size: 25px;
  }

  .border-radius-contact-us-1 {
    border-radius: 0 0 8px 8px;
  }

  .border-radius-contact-us-2 {
    border-radius: 8px 8px 0 0;
  }

}

@media (max-width:768px) {
  .container-header-title .header-title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: var(--primary);
  }


}


@media (max-width:576px) {
  .font-size-title {
    font-size: 14px;
    transition: var(--transition);

  }

  .font-size-subTitle {
    font-size: 12px;
    transition: var(--transition);
  }
}